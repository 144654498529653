/* Some CSS properties are overridden by the CMS (per site), see /components/layout/index.js */

:root {
  /* Palette */
  --primary: #84d6a9;
  --secondary: #002a32;
  --tertiary: #f6f2eb;
  --background: #f6f2eb;
  --link-normal: #756452;
  --link-hover: #390020;
  --link-visited: #7C2E77;
  --shadow-button: url(/images/content/CTA_skugga_moerkturkos_17e9f41722.png);
  --shadow-ball: url(/images/content/oegon_boll_600x_a883b274e9.png);
  --shadow-line: url(/images/content/oegonkollen_kant_600px_c34a39d0b3.png);
}